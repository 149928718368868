/*
Ankitha Suraksha
*/


export const INSERT_CUSTOMER_REQUEST = "INSERT_CUSTOMER_REQUEST";   // Insert customer to the API end point//
export const INSERT_CUSTOMER_SUCCESS = "INSERT_CUSTOMER_SUCCESS";  // Insert customer sucesfull at the API end point//
export const INSERT_CUSTOMER_FAILURE = "INSERT_CUSTOMER_FAILURE";  // Error in inserting th ecustomer at the API endpoint//



