// Author: Ashutosh Rao Chawan U

export const FETCH_ADMINS_REQUEST = "FETCH_ADMINS_REQUEST";
export const FETCH_ADMINS_SUCCESS = "FETCH_ADMINS_SUCCESS";
export const FETCH_ADMINS_FAILURE = "FETCH_ADMINS_FAILURE";
export const INSERT_ADMIN_REQUEST = "INSERT_ADMIN_REQUEST";
export const INSERT_ADMIN_SUCCESS = "INSERT_ADMIN_SUCCESS";
export const INSERT_ADMIN_FAILURE = "INSERT_ADMIN_FAILURE";
export const DELETE_ADMIN_REQUEST = "DELETE_ADMIN_REQUEST";
export const DELETE_ADMIN_SUCCESS = "DELETE_ADMIN_SUCCESS";
export const DELETE_ADMIN_FAILURE = "DELETE_ADMIN_FAILURE";
export const UPDATE_ADMIN_REQUEST = "UPDATE_ADMIN_REQUEST";
export const UPDATE_ADMIN_SUCCESS = "UPDATE_ADMIN_SUCCESS";
export const UPDATE_ADMIN_FAILURE = "UPDATE_ADMIN_FAILURE";
export const FETCH_ADMIN_REQUEST = "FETCH_ADMIN_REQUEST";
export const FETCH_ADMIN_SUCCESS = "FETCH_ADMIN_SUCCESS";
export const FETCH_ADMIN_FAILURE = "FETCH_ADMIN_FAILURE";
