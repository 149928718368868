/*Exporting all the Trip Actions Types*/

export const FETCH_TRIPS_REQUEST = "FETCH_TRIPS_REQUEST";
export const FETCH_TRIPS_SUCCESS = "FETCH_TRIPS_SUCCESS";
export const FETCH_TRIPS_FAILURE = "FETCH_TRIPS_FAILURE";
export const INSERT_TRIP_REQUEST = "INSERT_TRIP_REQUEST";
export const INSERT_TRIP_SUCCESS = "INSERT_TRIP_SUCCESS";
export const INSERT_TRIP_FAILURE = "INSERT_TRIP_FAILURE";
export const DELETE_TRIP_REQUEST = "DELETE_TRIP_REQUEST";
export const DELETE_TRIP_SUCCESS = "DELETE_TRIP_SUCCESS";
export const DELETE_TRIP_FAILURE = "DELETE_TRIP_FAILURE";
export const UPDATE_TRIP_REQUEST = "UPDATE_TRIP_REQUEST";
export const UPDATE_TRIP_SUCCESS = "UPDATE_TRIP_SUCCESS";
export const UPDATE_TRIP_FAILURE = "UPDATE_TRIP_FAILURE";
export const FETCH_TRIP_REQUEST = "FETCH_TRIP_REQUEST";
export const FETCH_TRIP_SUCCESS = "FETCH_TRIP_SUCCESS";
export const FETCH_TRIP_FAILURE = "FETCH_TRIP_FAILURE";
export const FETCH_DRIVERS_REQUEST = "FETCH_DRIVERS_REQUEST";
export const FETCH_DRIVERS_SUCCESS = "FETCH_DRIVERS_SUCCESS";
export const FETCH_DRIVERS_FAILURE = "FETCH_DRIVERS_FAILURE";